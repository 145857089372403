import {
  Heading,
  Box,
} from '@chakra-ui/react'

export const Container = props => (
  <Box shadow='base' py={3} px={5} borderRadius={5} {...props}>
    {props.title && <Heading color='gray.500' size='sm' mb={5}>{props.title}</Heading>}
    {props.children}
  </Box>
)