import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group';
import {
  Box,
} from '@chakra-ui/react'


const duration = 50;

export const Enter = props => {

  const { children, from, onEntered } = props

  const [inProp, setInProp] = useState(false)
  const nodeRef = React.useRef(null)
  useEffect(() => {
    setInProp(true)
  }, [])

  const defaultStyle = {
    transition: `${from} ${duration}ms linear`,
    [from]: "100%",
    position: 'relative',
    opacity: 0
  }
  
  const transitionStyles = {
    entering: { [from]: "100%", opacity: 0 },
    entered: { [from]: "0", opacity: 1 },
    exiting: { [from]: "0", opacity: 1 },
    exited: { [from]: "-100%", opacity: 0 },
  };

  const handleOnEntered = () => {
    setTimeout(() => onEntered(), duration)
  }

  return (
    <Box p={1} m={-1} sx={{ overflow: 'hidden' }}>
      <Transition in={inProp} timeout={0} nodeRef={nodeRef} onEntered={handleOnEntered}
      >
        {state => (
          <Box style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
            ref={nodeRef}
          >
            {
              children
            }
          </Box>
        )}
      </Transition>
    </Box>
  )
}