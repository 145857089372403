import React, { useState, useCallback } from 'react';

export const GroupContext = React.createContext({
  group: {},
  addGroup: () => {},
  removeGroup: () => {}
});

export const GroupProvider = ({ children }) => {
  const [group, setGroup] = useState();

  const removeGroup = () => setGroup(null);

  const addGroup = (Group) => setGroup(Group);

  const contextValue = {
    group,
    addGroup: useCallback((Group) => addGroup(Group), []),
    removeGroup: useCallback(() => removeGroup(), [])
  };

  return (
    <GroupContext.Provider value={contextValue}>
      {children}
    </GroupContext.Provider>
  );
}