import React, { useState, useEffect, useContext } from 'react'
import {
  Flex,
  Box,
  Text,
} from '@chakra-ui/react'

import Moment from 'react-moment'

import { t } from "i18next";

export const ActivityLine = props => {
  const { trip, user } = props
  return (
    <Flex mb={4} alignItems='center' variant='card' flexWrap='wrap' justifyContent='space-between'>
      <Box w={['100%', '100%', '100%', 1 / 3]} mb={2}>
        <Text>
          {user}
        </Text>
        <Text>
          <Moment format="DD/MM/YYYY">
            {trip.startDate}
          </Moment>
          &nbsp;&gt;&nbsp;
          <Moment format="DD/MM/YYYY">
            {trip.endDate}
          </Moment>
        </Text>
      </Box>
      <Box w={['100%', '100%', '100%', 1 / 3]} textAlign={['left', 'left', 'left', 'center']} mb={2}>
        <Text>{trip.endKm - trip.startKm} km ({trip.startKm} km
          &nbsp;&gt;&nbsp;
          {trip.endKm} km)
        </Text>
      </Box>
      <Box textAlign={['left', 'left', 'left', 'right']} w={['100%', '100%', '100%', 1 / 3]}>
        <Text>{t("Owner compensation")}: {trip.drivePrice} €</Text>
        <Text>{t("Service fee")}: {trip.servicePrice} €</Text>
        <Text>{t("Fuel")}: {trip.fuelPrice} €</Text>
        <Text fontWeight='bold'>{t("Total")}: {Math.round((trip.drivePrice + trip.servicePrice + trip.fuelPrice) * 100) / 100} €</Text>
        <Text>{t("Refueling")}: {trip.refuelingPrice} €</Text>
        <Text fontWeight='bold'>{t("Net total")}: {Math.round((trip.drivePrice + trip.servicePrice + trip.fuelPrice - trip.refuelingPrice) * 100) / 100} €</Text>
      </Box>
    </Flex>
  )
}