import {
  Heading,
  Flex,
  Box,
  Text,
  Divider
} from '@chakra-ui/react'

export const Page = props => {
  const { title, description, children, button } = props
  return <Box>
    <Flex alignItems={'center'} justifyContent='space-between'>
      <Box>
        <Heading fontSize={['2xl']} textAlign='left'>
          {
            title
          }
        </Heading>
        {
          description && <Text mt={2} fontSize="md" display={['none', 'none', 'block']}>
            {
              description
            }
          </Text>
        }
      </Box>
      <Box mr={['50px', '50px', '50px', 'inherit']}>
        {button}
      </Box>
    </Flex>
    <Divider mt={4} mb={5} display={['none', 'none', 'block']} />
    <Box mt={[4, 4, 0]}>
      {
        children
      }
    </Box>
  </Box>
}