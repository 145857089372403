import React from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function linkify(inputText) {
  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const URL_REGEX2 = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return inputText.split(" ")
    .map(
      part => {
        if (URL_REGEX.test(part))
          return <a href={part} target='_blank'>{part}</a>
        if (URL_REGEX2.test(part))
          return <a href={`https://${part}`} target='_blank'>{part}</a>
        return (part + " ")
      }
    );
}