import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useToast
} from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { useNavigate, useLocation } from "react-router-dom";
//import { UserContext, MessageContext } from "../../contexts";
import { useTranslation } from 'react-i18next';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Forgotten() {

  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [currentForm, setCurrentForm] = useState("request");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const toast = useToast()

  const navigate = useNavigate();
  //const { addMessage } = useContext(MessageContext);
  //const userContext = useContext(UserContext);

  const query = useQuery()
  const token = query.get("token")

  useEffect(() => {
    if (token) {
      setCurrentForm("reset");
    }
  }, [])

  function validateForm() {
    if (currentForm === 'request')
      return email.length > 4;
    return (password === password2);
  }

  function request() {
    /*if(password !== password2)
      return setError(t("Passwords don't match"));*/
    axios({
      method: 'POST',
      url: '/auth/reqreset',
      data: { email },
    })
      .then(resp => {
        toast({
          title: t("Password reset requested"),
          description: t("If the address exists, you'll receive an email."),
          duration: 9000,
          isClosable: true,
          position: 'top-right'
        })
        setEmailSent(true);
      })
      .catch((error) => {
        setError(error.response.data)
      });
  }

  function reset() {
    axios({
      method: 'POST',
      url: '/auth/resetpassword',
      data: { password, token },
    })
      .then(() => {
        toast({
          title: t("Reset successful"),
          description: t("Please log in with your new password."),
          duration: 9000,
          isClosable: true,
          position: 'top-right'
        })
        navigate('/signup');
      })
      .catch((error) => {
        setError(error.response.data)
      });
  }

  return (
    <>
      <Flex sx={{ height: '100vh' }} alignItems='center'>
        <Box width={['100%', '100%', '100%', '50%']} mx='auto' p={2}>
          <Box px={4} mx='auto' sx={{
            maxWidth: '500px',
            marginBottom: '200px'
          }}>
            {
              !emailSent ? <>
                <Box height='60px'>
                  {
                    error && <Alert status="error">
                      <AlertIcon />
                      <AlertTitle mr={2}>{t("Error")}</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                      <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError()} />
                    </Alert>
                  }
                </Box>
                <Box textAlign="center">
                  <Heading
                    fontWeight="bold"
                  >
                    {
                      currentForm === 'request'
                        ?
                        t("Password forgotten")
                        :
                        t("Define a new password")
                    }
                  </Heading>
                </Box>
                {
                  currentForm === 'request' && <Input
                    id='email'
                    name='email'
                    type='email'
                    placeholder='Email'
                    my={4}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                }
                {
                  currentForm === 'reset' &&
                  <>
                    <Input
                      id='password'
                      name='password'
                      type='password'
                      placeholder={t('Password')}
                      mt={4}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Input
                      id='password2'
                      name='password2'
                      type='password'
                      placeholder={t('Repeat password')}
                      mt={2}
                      mb={2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                  </>
                }
                <Button sx={{ cursor: 'pointer' }} mt={2} disabled={!validateForm()}
                  onClick={() => (
                    (currentForm === 'request' ? request() : reset())
                  )}
                  width="100%">
                  {currentForm === 'request' ? t('Send me a reset link') : t('Confirm new password')}
                </Button>
                <Box mt={3} textAlign='right'>
                  <Link href='/signup' mr={2}>{t("Log in")}</Link>
                </Box>
              </>
                :
                <>
                  <Box>
                    <Heading
                      fontWeight="bold"
                    >
                      {t('Email sent')}
                    </Heading>
                    <Text mt={3}>
                      {t('Check your inbox and spams for a reset link.')}
                    </Text>
                  </Box>
                </>
            }
          </Box>

        </Box>
        <Box width={'100%', '100%', '100%', '50%'} sx={{
          backgroundImage: "url('https://images.pexels.com/photos/5683084/pexels-photo-5683084.jpeg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: '100%',
          display: ['none', 'none', 'none', 'block']
        }}>
          &nbsp;
        </Box>
      </Flex>
    </>
  )
}