import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import {
  Flex,
  Heading,
  Box,
  Button,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useToast,
  Image,
  Divider,
  Checkbox
} from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext, MessageContext } from "../../contexts";
import { useTranslation } from 'react-i18next';
import logo from "../common/assets/caravail-logo-app-s.png"

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Signup() {

  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [currentForm, setCurrentForm] = useState("signin");
  const [error, setError] = useState("");

  const toast = useToast()

  const navigate = useNavigate();
  const { addMessage } = useContext(MessageContext);
  const userContext = useContext(UserContext);

  const query = useQuery()
  const token = query.get("token")
  const inviteToken = query.get("inviteToken")
  const reg = query.get("reg")

  useEffect(() => {
    if (userContext.user) {
      if (localStorage.getItem('lastGroup')) {
        navigate(`/groups/${localStorage.getItem('lastGroup')}`);
      }
      else {
        if (userContext.user.onboarding) {
          navigate('/onboarding');
        }
        else {
          navigate('/groups/tocreate/groups');
        }
      }
    }
    if (token) {
      axios({
        method: 'POST',
        url: `/auth/confirm/${token}`
      })
        .then(() => {
          toast({
            title: t("Account activated"),
            description: t("Log in to continue."),
            duration: 9000,
            isClosable: true,
            position: 'top-right'
          })
        }).catch(() => {
          /*toast({
            title: "Error activating your account",
            duration: 9000,
            isClosable: true,
            position: 'top-right'
          })*/
        })
    }
    if (inviteToken || reg) {
      setCurrentForm('register')
    }
  }, [userContext])

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function login() {
    axios({
      method: 'POST',
      url: '/auth/signin',
      data: { email, password, rememberMe },
    })
      .then(user => {
        userContext.setUser(user.data);
        toast({
          title: t("Hello there"),
          //description: "We sent you an email.",
          duration: 9000,
          isClosable: true,
          position: 'top-right'
        })
        if (localStorage.getItem('lastGroup')) {
          navigate(`/groups/${localStorage.getItem('lastGroup')}`);
        }
        else {
          if (userContext.user.onboarding) {
            navigate('/onboarding');
          }
          else {
            navigate('/groups/tocreate/groups');
          }
        }
      })
      .catch((error) => {
        setError(error && error.response && error.response.data)
      });
  }

  function signup() {
    axios({
      method: 'POST',
      url: '/auth/signup',
      data: { email, password, inviteToken },
    })
      .then(user => {
        if (user.data) {
          // if with invite hence authenticated
          user.data.isInvited = true;
          userContext.setUser(user.data);
          /*if (userContext.user.onboarding) {
            navigate('/onboarding?invite=true');
          }
          else {
            navigate('/groups/tocreate/groups');
          }*/
        }
        else {
          toast({
            title: t("Please activate your acount"),
            description: t("We sent you an email."),
            duration: 9000,
            isClosable: true,
            position: 'top-right'
          })
        }
      })
      .catch((error) => {
        setError(error.response.data)
      });
  }

  return (
    <>
      <Flex sx={{ height: '100vh' }} alignItems='center'>
        <Box width={['100%', '100%', '100%', '50%']} mx='auto' p={2}>
          <Box px={4} mx='auto' sx={{
            maxWidth: '500px',
            marginBottom: '200px'
          }}>
            <Box height='60px'>
              {
                error && <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                  <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError()} />
                </Alert>
              }
            </Box>
            <Box align="center">
              <Image
                src={logo}
                width={["15%"]}
                mb={10}
                onClick={() => (window.location.href = "https://caravail.com")}
                sx={{
                  cursor: "pointer"
                }}
                shadow="base"
                borderRadius={15}
                _hover={{
                  "-ms-transform": "scale(1.2)", /* IE 9 */
                  "-webkit-transform": "scale(1.2)", /* Safari 3-8 */
                  "transform": "scale(1.2)"
                }}
                transition="transform 0.2s ease"
              />
            </Box>
            <Flex justifyContent='space-between'>
              <Box>
                <Heading
                  fontWeight={currentForm === 'signin' ? 'bold' : ''}
                  opacity={currentForm === 'signin' ? 1 : 0.5}
                  onClick={() => (setCurrentForm('signin'))}
                  sx={{ cursor: 'pointer' }}
                  size="lg"
                >
                  {t("Login")}
                </Heading>
              </Box>
              <Box>
                <Heading
                  fontWeight={currentForm !== 'signin' ? 'bold' : ''}
                  opacity={currentForm !== 'signin' ? 1 : 0.5}
                  onClick={() => (setCurrentForm('register'))}
                  sx={{ cursor: 'pointer' }}
                  size="lg"
                >
                  {t("Register")}
                </Heading>
              </Box>
            </Flex>


            <Box
              _hover={{
                "-ms-transform": "scale(1.05)", /* IE 9 */
                "-webkit-transform": "scale(1.05)", /* Safari 3-8 */
                "transform": "scale(1.05)"
              }}
              transition="transform 0.2s ease"
              shadow="base"
              onClick={() => (window.location.href = "https://api.caravail.com/auth/login/facebook")} mt={4} bg="#3b5998 " color="white" py={3} px={4} cursor="pointer" borderRadius="3px">
              {t("Log in with Facebook")}
            </Box>
            <Box
              _hover={{
                "-ms-transform": "scale(1.05)", /* IE 9 */
                "-webkit-transform": "scale(1.05)", /* Safari 3-8 */
                "transform": "scale(1.05)"
              }}
              transition="transform 0.2s ease"
              shadow="base"
              onClick={() => (window.location.href = "https://api.caravail.com/auth/login/google")} mt={4} bg="#C4402F" color="white" py={3} px={4} cursor="pointer" borderRadius="3px">
              {t("Sign in with Google")}
            </Box>
            <Flex py={4} alignItems="center">
              <Divider />
              <Box mx={4} color='gray.500'>{t("Or")}</Box>
              <Divider />
            </Flex>

            <Input
              id='email'
              name='email'
              type='email'
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              id='password'
              name='password'
              type='password'
              placeholder={t('Password')}
              mt={4}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button sx={{ cursor: 'pointer' }} mt={4} disabled={!validateForm()} onClick={() => ((currentForm === 'signin' ? login() : signup()))} width="100%">
              {currentForm === 'signin' ? t('Log in') : t('Sign up')}
            </Button>
            <Flex justifyContent="space-between" pt={3}>
              <Box>
                {
                  (currentForm === 'signin') && <Checkbox isChecked={rememberMe} onChange={(e) => (setRememberMe(e.target.checked))}>{t("Remember me")}</Checkbox>
                }
              </Box>
              <Box>
                <Link href='/forgot'>{t("Password forgotten")}</Link>
              </Box>
            </Flex>
          </Box>

        </Box>
        <Box width={['100%', '100%', '100%', '50%']} sx={{
          backgroundImage: "url('https://images.pexels.com/photos/4577585/pexels-photo-4577585.jpeg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: '100%',
          display: ['none', 'none', 'none', 'block']
        }}>
          &nbsp;
        </Box>
      </Flex>
    </>
  )
}
