import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import './i18n' // to bundle
import * as serviceWorker from './serviceWorker';
import i18next from 'i18next'
import moment from 'moment';
import 'moment/locale/fr';



// set up moment locale
moment().locale(i18next.language)

//import process from "process";
//const prod = true
const prod = (window.location.host === 'app.caravail.com');
if(prod){
  axios.defaults.baseURL = "https://api.caravail.com"
  axios.defaults.withCredentials = true;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
serviceWorker.register();