import React, { useState, useContext, useEffect } from "react";
import axios from 'axios'
import {
  Flex,
  Box,
  Heading,
  Image,
  //useToast
} from '@chakra-ui/react'

import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate
} from "react-router-dom";

import { Form, StepperForm, FieldBuilder, isEmailValid } from "../common/Form"
//import { useQuery } from "../common"

import { UserContext } from "../../contexts/user";

import onboardingBg from "../common/assets/onboarding-bg.jpg"
import logo from "../common/assets/logo.png"


import { t } from "i18next"



export default function Onboarding() {

  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const userContext = useContext(UserContext);

  //const query = useQuery();
  //const isInvited = query.get("invite");

  //const toast = useToast()

  const fieldsInitial = [
    FieldBuilder(
      'firstname',
      'text',
      `${t('What is your firstname?')}*`,
      {
        placeholder: t('Your firstname'),
        isValid: (value) => ({
          status: !!value && (value.length > 2),
          message: t('This seems a little bit too short')
        }),
        bigField: true
      }
    ),
    FieldBuilder(
      'ownsCar',
      'choice',
      `${t('What can we do for you?')}*`,
      {
        bigField: true,
        selectOptions: [{
          value: false,
          text: t("I'm looking for a car to share")
        }, {
          value: true,
          text: t("I want to share my car")
        }],
        isValid: (value) => ({
          status: (value === false || value === true),
          message: t('Please choose a value')
        }),
      }
    ),
    FieldBuilder(
      'location',
      'location',
      `${t('Where do you live?')}*`,
      {
        placeholder: t('Inform your address'),
        isValid: (value) => ({
          status: value,
          message: t('Please inform the location')
        }),
        bigField: true
      }
    )
  ]
  if (userContext.user.isInvited)
    fieldsInitial.splice(1, 1);



  const [fields, setFields] = useState(fieldsInitial)

  useEffect(() => {
    // initializing form data to embed values and errors:
    const emptyFormData = {}
    fields.forEach(field => {
      emptyFormData[field.name] = {
        value: field.type === 'freemulti' ? [] : (field.type === 'location' ? null : ""),
        isValid: {
          status: false,
          message: ''
        }
      }
    })
    setFormData(emptyFormData)
  }, [])

  const onChange = (field, data, isValid) => {
    let updatedFieldObj = {}
    updatedFieldObj.value = data
    if (isValid)
      updatedFieldObj.isValid = isValid
    setFormData({
      ...formData,
      [field.name]: updatedFieldObj
    })
    if (field.name === "ownsCar" && data) { // if true
      setFields(
        [...fields,
        FieldBuilder(
          'groupType',
          'choice',
          `${t('What kind of group would you like to create?')}*`,
          {
            bigField: true,
            selectOptions: [{
              value: "private",
              text: t("I want to share my car with people I know and I will invite")
            }, {
              value: "public",
              text: t("I want to find people near me to share my car with")
            }, {
              value: "both",
              text: t("Both")
            }],
            isValid: (value) => ({
              status: (value),
              message: t('Please choose a value')
            }),
          }
        )
        ]
      )
    }
    if (field.name === "ownsCar" && data === false) {
      if (fields.length === 4) {
        let newFields = fields;
        newFields.pop();
        setFields(newFields)
      }
    }
  }

  const onNextValidation = field => {
    if (!field.isValid) return true // no validation function
    const isValid = field.isValid(formData[field.name].value)
    let updatedFieldObj = formData[field.name]
    updatedFieldObj.isValid = isValid
    setFormData({
      ...formData,
      [field.name]: updatedFieldObj
    })
    return isValid.status
  }

  const onSave = () => {
    // geometry.coordinates
    // properties.postcode
    // properties.city
    let dataToPost = {}
    Object.entries(formData).forEach(([key, fieldData]) => { // we extract the values, and get rid of the errors
      dataToPost[key] = fieldData.value
    })
    if (dataToPost.groupType === 'both')
      dataToPost.groupType = "public";
    dataToPost.onboarding = false;
    axios({
      method: 'PATCH',
      url: '/api/users/me?onboarding=true',
      data: dataToPost
    }).then(groupId => {
      if (dataToPost.ownsCar) {
        /*toast({
          title: t("We created your group"),
          description: t("Click on it to start configuring it"),
          duration: 9000,
          isClosable: true,
          position: 'top-right'
        })*/
        userContext.setUser({ ...userContext.user, firstGroupCreated: true })
      }
      navigate(`/groups/tocreate/groups`)
    })
  }

  const onCancel = () => {
    let dataToPost = { onboarding: false }
    axios({
      method: 'PATCH',
      url: '/api/users/me',
      data: dataToPost
    }).then(group => {
      navigate(`/groups/tocreate/groups`)
    })
  }
  // <Image src={logo} width="100px" opacity={0.8} mb={7} />
  return <>
    <Box sx={{ position: "fixed", bottom: "0", right: "0" }} p={2} opacity={0.4} display={["none", "none", "block"]}>
      <a target="_blank" href="http://www.freepik.com">Designed by pikisuperstar / Freepik</a>
    </Box>
    <Flex sx={{
      backgroundImage: `url('${onboardingBg}')`
    }} alignItems={["flex-start", "center"]} height="100vh">
      <Flex bg="white" width={["100%", "100%", "75%"]} height="100%" alignItems="center">
        <Box p={[3, 10]} mb={[0, 10]} bg="white" ml={[0, 0, 0, "100px", "170px", "470px"]} mr={[0, 0, 0, "170px"]} width="100%">
          <Heading size="xl" mb={10}>{t("Welcome! Let's create your profile")}</Heading>
          {
            formData &&
            <StepperForm {...{ fields, formData, onChange, onNextValidation, onSave, onCancel }} smallHeader={true} title={t("Let us know a bit more about you")} cancelActionText={t("Skip")} saveActionText={t("Save")} />
          }
        </Box>
      </Flex>
    </Flex>
  </>
}