import React, { useRef, useState } from 'react';

import {
  Heading,
  Text,
  Box,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Button
} from '@chakra-ui/react'

//import 'tinymce/tinymce';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';

import { t } from "i18next";

export const Editor = props => {
  const { initialValue, save, readonly } = props
  const editorRef = useRef(null);

  const [inEdit, setInEdit] = useState(!readonly)

  return <>
    {
      inEdit ?
        <>
          <TinyEditor
            //tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce.min.js`}
            onInit={(evt, editor) => editorRef.current = editor}
            value={initialValue}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | image | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              branding: false
            }}
            onEditorChange={() => save(editorRef.current.getContent())}
          />
          {/*<Box textAlign='right'>
            <Button mt={4} onClick={() => { setInEdit(false); save(editorRef.current.getContent()); }}>Save</Button>
          </Box>*/}
        </>
        :
        <>
          <Box p={3} sx={
            {
              "border": "1px solid grey",
              '.editorText':
              {
                'h1': {
                  fontSize: "24px"
                },
                'h2': {
                  fontSize: "22px"
                },
                'h3': {
                  fontSize: "20px"
                },
                'ul,ol': {
                  paddingLeft: "40px"
                }
              }
            }
          }>
            <Box className="editorText" dangerouslySetInnerHTML={{ __html: initialValue }}>
            </Box>
          </Box>
          {/*
            save && <Box textAlign='right'>
              <Button mt={4} onClick={() => setInEdit(true)}>{t("Edit")}</Button>
            </Box>
          */}
        </>
    }
  </>
}