import React, { useContext } from 'react'
import { MessageContext } from "../../contexts";

import {
  Flex,
  Text,
  Box,
  Button,
} from '@chakra-ui/react'

export function MessageNotification() {
  const { message, removeMessage } = useContext(MessageContext);

  const handleSubmit = () => {
    removeMessage();
  };

  return (
    <>
      { message && message.message &&
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 99 }} w='100%' py={2} px={4}>
          <Flex width="100%" bg='highlight' alignItems='center' variant='card' pl={4} pr={0} py={2}>
            <Box width='75%' pr={4}>
              <Text color='text' fontWeight='bold'>THIS HAS TO BE MIGRATED - {message.message}</Text>
            </Box>
            <Box width='25%' sx={{ textAlign: 'right' }}>
              <Button onClick={handleSubmit} variant='round' bg='highlight' color='text'>
                Ok
                </Button>
            </Box>
          </Flex>
        </Box>
      }
    </>
  )
}