import React, { useContext, useState, useEffect, Suspense } from 'react'
//import { ThemeProvider } from 'theme-ui'
//import { ChakraProvider } from "@chakra-ui/react"
//import { ThemeProvider, ColorModeProvider } from "@chakra-ui/react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import {
  Box,
} from '@chakra-ui/react'

import {
  Signup,
  Forgotten
} from './components/Authentication'
//import Main from './components/Main'
import Onboarding from './components/Onboarding'
/*import { Group as GroupSelector } from './components/Main/Group'
import { NewGroup } from './components/Main/Group/New'*/

//import { UserContext } from "./contexts";
import { UserContext, MessageProvider, socket, SocketContext } from "./contexts"; // GroupProvider
import { GroupProvider } from "./contexts"
import { NotFound, Loader } from './components/common';
//import { Landing } from './components/Landing';
import axios from 'axios';

import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';

import socketio from "socket.io-client";
import { hotjar } from 'react-hotjar';

hotjar.initialize("2783359", 6);

const Main = React.lazy(() => import('./components/Main'));

const theme = extendTheme({
  breakpoints: ['40em', '52em', '64em', '76em'],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    main: '#5B9DE4',
    text: '#011627',
    //background: '#fff',
    background: '#F6F5FB',
    //primary: '#2ec4b6',
    //primary: '#023e8a',
    /*primary: '#3788D8',
    secondary: '#e71d36',
    accent: '#ff9f1c',
    highligh: '#a0c',*/
    //primary: '#35212A',
    //primary: '#FFDEF3',
    /*secondary: '#FF61BE',
    accent: '#3B55CE',*/
    highlight: '#FFDEF3',
    primary: 'rgb(0, 110, 254)',
    secondary: 'rgb(76, 9, 190)',
    accent: 'rgb(219, 120, 5)',
    muted: '#ddd',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        primary: '#0cf',
      }
    }
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],
  fonts: {
    //body: 'Lato, sans-serif',
    //heading: 'inherit',
    monospace: 'Menlo, monospace',
    body: '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    heading: '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    big: '0px 0px 15px 5px rgba(0,0,0,0.4)'
  },
  variants: {
    round: {
      //borderRadius: "5px",
      boxShadow: 'small',
      borderRadius: 20,
    },
    bordered: {
      //border: '1px solid',
      borderColor: 'muted',
      borderTop: 'none',
    },
    card: {
      //boxShadow: 'small',
      borderRadius: 5,
      background: 'white',
      padding: 3,
    },
    cardWithShadow: {
      boxShadow: 'big',
      borderRadius: 5,
      background: 'white',
      padding: 3,
    }
  },
  text: {
    link: {
      color: 'black',
      fontWeight: 'bold',
    },
    bold: {
      fontWeight: 'bold',
    }
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    outline: {
      color: 'text',
      background: 'inherit',
      border: '2px solid',
      borderColor: 'text',
    },
    round: {
      borderRadius: 5,
      fontWeight: 'bold',
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 4,
      paddingRight: 4,
      cursor: 'pointer',
    }
  },
  forms: {
    textarea: {
      fontFamily: 'inherit',
      outline: 'none',
    },
    light: {
      label: {
        fontWeight: 'bold',
        marginBottom: 2,
      },
      input: {
        border: 'none',
        borderBottom: '1px solid #ccc',
        outline: 'none',
        padding: 0,
        paddingTop: 2,
        paddingBottom: 2,
        //marginTop: 2,
        marginBottom: 4,
        fontFamily: 'inherit'
      },
      textarea: {
        fontFamily: 'inherit',
        outline: 'none',
      },
    },
    big: {
      label: {
        fontWeight: 'bold',
        marginBottom: 2,
        fontSize: 4,
      },
      input: {
        fontSize: 4,
        /*border: 'none',
        borderBottom: '1px solid #ccc',
        outline: 'none',*/
        padding: 2,
        /*paddingTop: 2,
        paddingBottom: 2,*/
        //marginTop: 2,
        outlineColor: 'primary',
        background: 'white',
        borderRadius: '3px'
      },
      textarea: {
        fontFamily: 'inherit',
        //outline: 'none',,
        background: 'white'
      },
      button: {
        fontWeight: 'bold',
        cursor: 'pointer',
      }
    }
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function PrivateRoute({ ...props }) {
  const userContext = useContext(UserContext);
  if (userContext.user) {
    return <Route {...props} />
  }
  return <Navigate to="/signup" />
}

function App() {

  const [authenticationChecked, setAuthenticationChecked] = useState(false)
  const [user, setUser] = useState(null)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/api/users/me',
    }).then((user) => {
      setUser(user.data)
    }).finally(() => {
      setAuthenticationChecked(true)
    })
  }, [])

  useEffect(() => {
    if (user) {
      const prod = (window.location.host === 'app.caravail.com');
      
      if (prod) {
        const sio = socketio("https://api.caravail.com", {
          withCredentials: true,
        });
        setSocket(sio.connect("https://api.caravail.com"))
      }
      else {
        setSocket(socketio.connect("localhost"))
      }
      // Identify the user
      hotjar.identify(user.id, { email: user.email });

      /*// Add an event
      hotjar.event('button-click');

      // Update SPA state
      hotjar.stateChange('/my/page');*/
    }
  }, [user])

  return (
    <ErrorBoundary>
      <ChakraProvider>
        <SocketContext.Provider value={socket}>
          <UserContext.Provider value={{ user, setUser }}>
            <MessageProvider>
              <GroupProvider>
                <Box sx={{
                  fontFamily: 'body',
                  '* ::-webkit-scrollbar': {
                    width: '2px',
                    height: '2px',
                    backgroundColor: "#eee",
                    padding: "2px"
                  },
                  '* ::-webkit-scrollbar-thumb': {
                    backgroundColor: '#800080AA',
                    borderRadius: '1px'
                  },
                }}
                >
                  {authenticationChecked &&
                    <Router>
                      <Routes>
                        <Route path="/">
                          <Signup />
                        </Route>
                        <Route path="/signup">
                          <Signup />
                        </Route>
                        <Route path="/forgot">
                          <Forgotten />
                        </Route>
                        <PrivateRoute path="/onboarding" element={<Onboarding />} />

                        <PrivateRoute path="/groups/:groupId/*" element={
                          <Suspense fallback={<Loader />}>
                            <Main />
                          </Suspense>
                        } />
                        <Route element={<NotFound />} />
                      </Routes>
                    </Router>
                  }
                </Box>
              </GroupProvider>
            </MessageProvider>
          </UserContext.Provider>
        </SocketContext.Provider>
      </ChakraProvider>
      <AddToHomeScreen
        lifespan={590}
        customPromptContent={
          {
            title: "Installer l'application est nécessaire pour un usage simplifié.",
            src: "https://fr.caravail.com/assets/car-apropos.svg",
            cancelMsg: "Plus tard",
            installMsg: "Installer (conseillé)",
            guidanceCancelMsg: "Fermer"
          }
        }
      />
    </ErrorBoundary >
  );
}

export default App;
