import {
  Flex,
  Box
} from '@chakra-ui/react'
import { LockIcon } from '@chakra-ui/icons'
import { t } from "i18next"
export const Loader = () => (
  <Flex bg="white" position="fixed" height="100vh" width="100%" alignItems="center" justifyContent="center">
    <Flex alignItems="center"><LockIcon mr={3} />{t("Loading your carsharing group")}</Flex>
  </Flex>
)