import React, { useState } from 'react'
import {
  Flex,
  Box,
  Button,
  Heading
} from '@chakra-ui/react'
import { Transition } from 'react-transition-group';
import { useEffect } from 'react/cjs/react.development';

import { Enter } from './animations'

import { useTranslation } from 'react-i18next';

const duration = 1300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const Stepper = props => {
  const {
    title,
    steps,
    saveAction,
    saveActionText,
    cancelAction,
    smallHeader,
    cancelActionText
  } = props

  const [stepIndex, setStepIndex] = useState(0)
  const [fromProp, setFromProp] = useState('right')
  const [autoFocus, setAutoFocus] = useState(false)

  const { t, i18n } = useTranslation();

  const goBack = () => {
    if (stepIndex >= 1)
      setAutoFocus(false)
    setStepIndex(stepIndex - 1)
    setFromProp('left')
  }

  const goNext = () => {
    if (!steps[stepIndex].isStepValid())
      return
    if (stepIndex < (steps.length - 1))
      setAutoFocus(false)
    setStepIndex(stepIndex + 1)
    setFromProp('right')
  }

  const handleSaveAction = () => {
    if (!steps[stepIndex].isStepValid())
      return
    saveAction()
  }

  const handleFocus = () => {
    setAutoFocus(true)
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      stepIndex === (steps.length - 1) ? handleSaveAction() : goNext();
    }
  }

  return (
    <>
      <Box mb={4} onKeyPress={onKeyPress}>
        <Flex justifyContent='space-between' flexWrap="wrap-reverse" mb={4}>
          <Box>
            <Heading size={smallHeader ? "sm" : "md"} mb={3}>{title}</Heading>
          </Box>
          <Box>
            <Heading size={smallHeader ? "sm" : "md"} mb={3}>{stepIndex + 1} / {steps.length}</Heading>
          </Box>
        </Flex>
        <Enter key={stepIndex} from={fromProp} onEntered={handleFocus}>
          {
            steps[stepIndex].renderContent(autoFocus)
          }
        </Enter>
      </Box>
      <Flex justifyContent="space-between" mb={3}>
        {
          (stepIndex === 0 && cancelAction) &&
          <Button variant='outline' size='xl' width={1 / 3} onClick={() => cancelAction()} p={4}>
            {
              cancelActionText ?
                cancelActionText
                :
                t('Back')}
          </Button>
        }
        {
          stepIndex !== 0 && <Button variant='outline' size='xl' width={1 / 3} onClick={() => goBack()} p={4}>
            {t('Back')}
          </Button>
        }
        <Button size='xl' width={1 / 3} onClick={stepIndex === (steps.length - 1) ? handleSaveAction : goNext} colorScheme={'green'} p={4}>
          {stepIndex === (steps.length - 1) ? saveActionText : t('Next')}
        </Button>
      </Flex>
    </>
  )
}