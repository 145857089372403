import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  Input,
  Checkbox,
  Switch,
  FormLabel,
  Select,
  Textarea
} from '@chakra-ui/react'

import { Stepper } from './Stepper'

import { Editor } from './Editor'

import { t } from "i18next";

export const isEmailValid = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return false
  }
  return true
}

export const FieldBuilder = (name, type, friendlyName, options) => {
  const { placeholder, isValid, isValidOnEnter, selectOptions, bigField } = options || {}
  return { name, type, friendlyName, placeholder, isValid, isValidOnEnter, selectOptions, bigField }
}

export const FreeMulti = props => {

  const { field, fieldData, onChange, autofocus } = props

  const [seizedValue, setSeizedValue] = useState('')

  /*const inputRef = useRef(null);

  useEffect(() => {
    const instance = inputRef.current;
    return () => {
      let value = instance.value.trim();
      if (value) {
        let isValid = { status: true }
        if (field.isValidOnEnter)
          isValid = field.isValidOnEnter(value, fieldData.value)
        if (isValid.status)
          onChange(field, [...fieldData.value, value])
      }
    }
  }, []);*/

  const onSeizedValueChange = event => {
    setSeizedValue(event.target.value)
  }

  const handleKD = event => {
    if (["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault();

      let value = seizedValue.trim();

      if (value) {
        let isValid = { status: true }
        if (field.isValidOnEnter)
          isValid = field.isValidOnEnter(value, fieldData.value)
        if (isValid.status) {
          setSeizedValue("")
          onChange(field, [...fieldData.value, value])
        }
        else {
          onChange(field, fieldData.value, isValid) // to do, is it a good pattern?
        }
      }
    }
  }

  const handleDelete = item => {
    const selectedValues = fieldData.value.filter(i => i !== item)
    onChange(field, selectedValues)
  }

  return <Box>
    <FormLabel htmlFor={field.name}>{field.friendlyName}</FormLabel>
    <Flex alignItems='flex-start' flexWrap='wrap'>
      {
        !!fieldData && fieldData.value &&
        Object.entries(fieldData.value).map(([k, v]) =>
          <Flex
            key={k}
            flexBasis='auto'
            flexShrink={0}
            p={2}
            mr={1}
            mb={2}
            bg='blue.500'
            color='white'
            borderRadius={5}
          >
            <Box>
              {v}
            </Box>
            <Box
              onClick={() => handleDelete(v)}
              sx={{
                cursor: "pointer"
              }}
              ml={2}
            >
              &times;
            </Box>
          </Flex>
        )
      }
    </Flex>
    <Input
      id={field.name}
      name={field.name}
      placeholder={field.placeholder}
      value={seizedValue}
      onChange={onSeizedValueChange}
      onKeyDown={handleKD}
      autoFocus={autofocus}
    //ref={inputRef}
    />
    {/* </Flex> */}
  </Box>
}

export const Location = props => {

  const { field, fieldData, readonly, onChange, autofocus } = props
  const [seizedLocation, setSeizedLocation] = useState(fieldData.value ? fieldData.value.properties.label : "")
  const [locationOptions, setLocationOptions] = useState([])

  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  const handleLocationChange = (e) => {
    setSeizedLocation(e.target.value)
    if (fieldData.value) {
      onChange(field, null)
    }
    axios.get(`https://api-adresse.data.gouv.fr/search/?q=${e.target.value}`, { withCredentials: false }).then((locationData) => {
      setLocationOptions(locationData.data.features)
    })
  }

  return <Box>
    <FormLabel htmlFor='location' size={field.bigField ? 'lg' : ''}>{field.friendlyName}</FormLabel>
    {
      readonly ?
        <>
          {(seizedLocation && seizedLocation) || ''}
        </>
        :
        <>
          <Input
            id='location'
            name='location'
            onChange={handleLocationChange}
            value={seizedLocation}
            //autoFocus={autofocus}
            ref={inputElement}
            size={field.bigField ? 'lg' : 'md'}
            variant={field.bigField ? 'flushed' : 'outline'}
          />
          {
            locationOptions[0] &&
            <Box py={1} sx={{ zIndex: 99 }} bg="white">
              {
                locationOptions.map((option, i) => (
                  <Text py={2} px={2} sx={{
                    cursor: 'pointer'
                  }}
                    _hover={{
                      background: "green.100"
                    }}
                    key={i}
                    onClick={() => {
                      onChange(field, option)
                      setLocationOptions([])
                      setSeizedLocation(option.properties.label)
                      inputElement.current.focus()
                    }}
                  >
                    {option.properties.label}
                  </Text>
                ))
              }
            </Box>
          }
        </>
    }
  </Box>
}

export const LocationNew = props => {

  const { field, fieldData, onChange, autofocus } = props
  const [seizedLocation, setSeizedLocation] = useState(fieldData.value ? fieldData.value.properties.label : "")
  const [locationOptions, setLocationOptions] = useState([])

  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  const handleLocationChange = (e) => {
    setSeizedLocation(e.target.value)
    if (fieldData.value) {
      onChange(field, null)
    }
    axios.get(`https://api-adresse.data.gouv.fr/search/?q=${e.target.value}`, { withCredentials: false }).then((locationData) => {
      setLocationOptions(locationData.data.features)
    })
  }

  return <Box>
    <FormLabel htmlFor='location' size={field.bigField ? 'lg' : ''}>{field.friendlyName}</FormLabel>
    <Input
      id='location'
      name='location'
      onChange={handleLocationChange}
      value={seizedLocation}
      //autoFocus={autofocus}
      ref={inputElement}
      size={field.bigField ? 'lg' : 'md'}
      variant={field.bigField ? 'flushed' : 'outline'}
    />
    <Select>
      {
        locationOptions[0] &&
        locationOptions.map((option, i) => (
          <option py={2} px={2} sx={{
            cursor: 'pointer'
          }}
            _hover={{
              background: "green.100"
            }}
            key={i}
            onClick={() => {
              onChange(field, option)
              setLocationOptions([])
              setSeizedLocation(option.properties.label)
              inputElement.current.focus()
            }}
          >
            {option.properties.label}
          </option>
        ))
      }
    </Select>
  </Box>
}

export const TextField = (props) => {
  const { field, fieldData, readonly, onChange, autofocus, onFocusOut } = props
  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  const handleFocusOut = e => {
    if (!onFocusOut) return
    onFocusOut(field, e.target.value)
  }

  return <Box>
    <FormLabel htmlFor={field.name} sx={{ fontSize: field.bigField ? '2xl' : 'md' }}>
      {field.friendlyName}
    </FormLabel>
    {
      readonly ?
        <>
          {(fieldData && fieldData.value) || ''}
        </>
        :
        <Input
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          value={(fieldData && fieldData.value) || ''}
          onChange={e => onChange(field, e.target.value)}
          onFocus={e => e.currentTarget.select()}
          ref={inputElement}
          //autoFocus={autofocus}
          onBlur={handleFocusOut}
          size={field.bigField ? 'lg' : 'md'}
          variant={field.bigField ? 'flushed' : 'outline'}
        />
    }

  </Box>
}

export const MultiTextField = (props) => {
  const { field, fieldData, readonly, onChange, autofocus, onFocusOut } = props
  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  const handleFocusOut = e => {
    if (!onFocusOut) return
    onFocusOut(field, e.target.value)
  }

  return <Box>
    <FormLabel htmlFor={field.name} sx={{ fontSize: field.bigField ? '2xl' : 'md' }}>
      {field.friendlyName}
    </FormLabel>
    {
      readonly ?
        <>
          {(fieldData && fieldData.value) || ''}
        </>
        :

        <Textarea
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          value={(fieldData && fieldData.value) || ''}
          onChange={e => onChange(field, e.target.value)}
          onFocus={e => e.currentTarget.select()}
          ref={inputElement}
          //autoFocus={autofocus}
          onBlur={handleFocusOut}
          size={field.bigField ? 'lg' : 'md'}
          variant={field.bigField ? 'flushed' : 'outline'}
        />
    }
  </Box>
}

export const RichTextField = props => {

  const { field, fieldData, readonly, onChange } = props

  return <Box>
    <FormLabel htmlFor={field.name} sx={{ fontSize: field.bigField ? '2xl' : 'md' }}>
      {field.friendlyName}
    </FormLabel>
    <Editor
      id={field.name}
      name={field.name}
      initialValue={(fieldData && fieldData.value) || ''}
      save={val => onChange(field, val)}
      readonly={readonly}
    />
  </Box>

}

export const DateField = (props) => {
  const { field, fieldData, readonly, onChange, autofocus } = props
  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  return <Box>
    <FormLabel htmlFor={field.name}>{field.friendlyName}</FormLabel>
    {
      readonly ?
        <>
          {(fieldData && fieldData.value) || ''}
        </>
        :
        <Input
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          value={(fieldData && fieldData.value) || ''}
          onChange={e => onChange(field, e.target.value)}
          onFocus={e => e.currentTarget.select()}
          ref={inputElement}
          type='date'
        //autoFocus={autofocus}
        />
    }
  </Box>
}

export const CheckBoxField = props => {
  const { field, fieldData, onChange } = props
  return <Box>
    <FormLabel>{field.friendlyName}
      <Checkbox ml={3} id={field.name} name={field.name} checked={(fieldData && fieldData.value) || false} onChange={e => onChange(field, e.target.checked)} />
    </FormLabel>
  </Box>
}

export const SwitchField = props => {
  const { field, fieldData, onChange, autofocus } = props

  const inputElement = useRef(null);
  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  const onSwitch = e => {
    onChange(field, !fieldData.value)
  }
  return <Box mb={2}>
    <FormLabel>{field.friendlyName}</FormLabel>
    <Flex justifyContent='flex-end' alignItems='center'>
      <Switch id={field.name} name={field.name} isChecked={(fieldData && fieldData.value) || false} onChange={onSwitch} sx={{ cursor: 'pointer' }} size='lg'
        ref={inputElement} />
      <Text ml={2}>
        {fieldData.value ? t('Yes') : t('No')}
      </Text>
    </Flex>
  </Box>
}

export const userColors = ["#cc2a36", "#eb6841", "#4f372d", "#00a0b0", "#edc951"]

export const ColorPicker = (props) => {
  const { field, fieldData, onChange } = props
  return <Box>
    <FormLabel htmlFor={field.name}>{field.friendlyName}</FormLabel>
    <Flex justifyContent='flex-end'>
      {userColors.map(color =>
        <Box width='50px' key={color}>
          <Box sx={{
            height: '50px',
            width: '50px',
            display: 'inline-block',
            borderRadius: '25px',
            background: color,
            border: `5px solid ${fieldData.value === color ? '#222' : 'white'}`,
            cursor: 'pointer',
          }}
            onClick={() => onChange(field, color)}
          >
            &nbsp;
          </Box>
        </Box>
      )}
    </Flex>
  </Box>
}

export const ChoiceField = (props) => {
  const { field, fieldData, readonly, onChange, autofocus, onFocusOut } = props
  const inputElement = useRef(null);

  useEffect(() => {
    if (autofocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [autofocus]);

  /*const handleFocusOut = e => {
    if (!onFocusOut) return
    onFocusOut(field, e.target.value)
  }*/

  const handleClick = value => {
    onChange(field, value)
    /*const ke = new KeyboardEvent('keydown', {
      bubbles: true, cancelable: true, keyCode: 13
    });
    document.body.dispatchEvent(ke);*/
  }

  return <Box>
    <FormLabel htmlFor={field.name} sx={{ fontSize: field.bigField ? '2xl' : 'md' }}>
      {field.friendlyName}
    </FormLabel>
    <Flex
      id={field.name}
      name={field.name}
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {
        field.selectOptions.map((option, i) => (
          i === 0 ?
            <Button
              key={i}
              size={field.bigField ? 'lg' : 'md'}
              ref={inputElement}
              onClick={e => handleClick(option.value)}
              my={5}
              variant={(fieldData && fieldData.value === option.value) ? 'outline' : 'ghost'}
              colorScheme="pink"
              height="100px"
              flexGrow={1}
              mx={2}
              disabled={readonly}
              sx={{
                whiteSpace: "pre-wrap"
              }}
            >
              {option.text}
            </Button>
            :
            <Button
              key={i}
              size={field.bigField ? 'lg' : 'md'}
              onClick={e => handleClick(option.value)}
              my={5}
              variant={(fieldData && fieldData.value === option.value) ? 'outline' : 'ghost'}
              colorScheme="pink"
              height="100px"
              flexGrow={1}
              mx={2}
              disabled={readonly}
              sx={{
                whiteSpace: "pre-wrap"
              }}
            >
              {option.text}
            </Button>
        )
        )
      }
    </Flex>
  </Box>
}

const renderField = (field, formData, readonly, onChange, autofocus, onFocusOut) => {
  let renderedField = null
  switch (field.type) {
    case 'text':
    case 'email':
      renderedField = <TextField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} onFocusOut={onFocusOut} autofocus={autofocus} />
      break
    case 'multitext':
      renderedField = <MultiTextField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} onFocusOut={onFocusOut} autofocus={autofocus} />
      break
    case 'richtext':
      renderedField = <RichTextField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} />
      break
    case 'choice':
      renderedField = <ChoiceField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} onFocusOut={onFocusOut} autofocus={autofocus} />
      break
    case 'date':
      renderedField = <DateField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
    case 'freemulti':
      renderedField = <FreeMulti key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
    case 'location':
      renderedField = <Location key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
    case 'color':
      renderedField = <ColorPicker key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
    case 'checkbox':
      renderedField = <CheckBoxField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
    case 'switch':
      renderedField = <SwitchField key={field.name} field={field} fieldData={formData[field.name]} readonly={readonly} onChange={onChange} autofocus={autofocus} />
      break
  }
  return <>
    {
      renderedField
    }
    {
      (formData[field.name] && formData[field.name].isValid && !formData[field.name].isValid.status) && <Text mt={2}>{formData[field.name].isValid.message}</Text>
    }
  </>
}

export const Form = props => {
  const {
    title,
    fields,
    formData,
    onChange,
    onFocusOut,
    onSave,
    onCancel,
    saveActionText,
    guidelines,
    fieldsWidth,
    readonly
  } = props

  return formData ? <Flex
    as='form'
    onSubmit={e => e.preventDefault()}
    //pb={3}
    variant='forms.light'>
    <Box width={guidelines ? '66%' : '100%'}>
      {
        title &&
        <Heading mb={3} size='md'>{title}</Heading>
      }
      <Flex mb={3} mx={[0, 0, 0, -3]} flexWrap="wrap">
        {
          fields.map(field =>
            <Box key={field.name} width={['100%', '100%', fieldsWidth || '50%', fieldsWidth || '50%', fieldsWidth || '33%']} py={4} px='3'>
              {
                renderField(field, formData, readonly, onChange, false, onFocusOut)
              }
            </Box>
          )
        }
      </Flex>
      {
        !readonly && <Flex justifyContent="flex-end" flexWrap='wrap' mt={2}>
          {
            onCancel && <Button onClick={onCancel} p={2} ml={3} mt={2} sx={{ fontWeight: 'bold' }} minW={['100%', '50%', '25%']}>
              {t('Cancel')}
            </Button>
          }
          <Button onClick={onSave} ml={3} p={2} mt={2} sx={{ fontWeight: 'bold' }} minW={['100%', '50%', '25%']} colorScheme='green'>
            {saveActionText || t('Save')}
          </Button>
        </Flex>
      }
    </Box>
    {
      guidelines && <Box width='33%' ml={5} sx={{ border: '1px solid #eee' }} px={4} py={2}>
        {
          guidelines.map((guideline, i) =>
          (<Box my={3} key={i}>
            <Heading mb={2}>{guideline.title}</Heading>
            <Text>{guideline.content}</Text>
          </Box>)
          )
        }
      </Box>
    }
  </Flex >
    : <Text>Please initialize form data</Text>
}

const generateFormSteps = (fields, formData, onChange, onNextValidation) => {
  return fields.map(field => ({
    renderContent: transitionComplete => {
      return <React.Fragment key={field.name}>
        {
          renderField(field, formData, false, onChange, transitionComplete)
        }
      </React.Fragment>
    },
    isStepValid: () => {
      return onNextValidation(field)
    }
  }))
}

export const StepperForm = props => {
  const {
    title,
    fields,
    formData,
    onChange,
    onNextValidation,
    onSave,
    onCancel,
    saveActionText,
    cancelActionText
  } = props

  const steps = generateFormSteps(fields, formData, onChange, onNextValidation)

  return <Stepper title={title} steps={steps} saveAction={onSave} saveActionText={saveActionText} cancelAction={onCancel} cancelActionText={cancelActionText} />
}