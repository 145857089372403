import React, { useState, useCallback } from 'react';

export const MessageContext = React.createContext({
  message: null,
  addMessage: () => {},
  removeMessage: () => {}
});

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  const removeMessage = () => setMessage(null);

  const addMessage = (message, status) => setMessage({ message, status });

  const contextValue = {
    message,
    addMessage: useCallback((message, status) => addMessage(message, status), []),
    removeMessage: useCallback(() => removeMessage(), [])
  };

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
}