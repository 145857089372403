import React from "react";
import {
  Text,
  Box,
} from '@chakra-ui/react'

export function NotFound() {
  return (
    <Box><Text>not found</Text></Box>
  );
}