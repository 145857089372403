import React /*, { useState, useCallback } */ from 'react';

export const UserContext = React.createContext({
  user: null,
  setUser: () => {},
});

/*export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const contextValue = {
    user,
    setUser: useCallback(User => setUser(User), []),
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}*/